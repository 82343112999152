/* ========================= */
/*          General          */
/* ========================= */
  
  body {
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
    color: #242424;
  }

  .dark-theme-row {
    background-color: #242424;
    color: #fff;
    justify-content: center;
    align-content: center;
    min-height: 25vh;
    display: grid;
  }

  .light-theme-row {
    background-color: #fff;
    color: #242424;
    justify-content: center;
    align-content: center;
    min-height: 25vh;
    display: grid;
  }

  .hidden {
    display: none;
  }

  .keywords {
    color: #272727;
    font-size: 9;
    clear: left;
  }

/* ========================= */
/*          NavBar           */
/* ========================= */
  
  header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    color: #212529;
  }
  
  .nav-area {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px 20px;
    width: 100%;
    z-index: 999;
  }

  .nav-area ul {
    margin-bottom: 0;
  }
  
  .menus {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
  }
  
  .menu-items {
    position: relative;
    font-size: 14px;
  }
  
  .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
    text-transform: uppercase;
  }
  
  button span {
    margin-left: 3px;
  }
  
  .menu-items > a, .menu-items button {
    color: #fff;
    font-weight: 600;
    text-align: left;
    padding: 0.7rem 1rem;
  }
  
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
  }
  
  .dropdown {
    position: absolute;
    right: -25px;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #242424;
    border-radius: 0.5rem;
    display: none;
  }

  .dropdown > li > a
  {
    text-align: center;
  }
  
  .dropdown.show {
    display: block;
  }
  
  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .react-switch-bg {
    height: 26px !important;
  }
  
/* ========================= */
/*     NavBar   Mobile       */
/* ========================= */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 50px;
  height: 75px;
  left: 25px;
  top: 0px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #a90000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  left: inherit;
  z-index: 1100;
  height: 100%;
  transition: all 0.5s ease 0s;
  margin-left: -50%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #191919;
  padding: 2.5em 0.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  list-style: none;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  /* background: rgba(0, 0, 0, 0.3); */
}

/* Individual item */
.bm-item {
  display: inline-block;

  color: #d1d1d1;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.bm-item:hover {
  color: #ffffff;
}

.float-right {
  position: fixed;
  top: 20px;
  right: 20px;
}

/* ========================= */
/*          Content          */
/* ========================= */

  h1 {
    text-align: center;
  }
  
  .mainContent {
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .section-title {
    text-transform: uppercase;
    font-weight: 800;
    text-decoration: underline;
    text-decoration-color: #9F1223;
    text-decoration-thickness: 4px;
    font-size: 22px;
    text-align: center;
    padding-bottom: 1em;
  }

  .section-text {
    text-align: center;
  }

  .section-description p {
    line-height: 20px;
  }

  .content {
    width: 100%;
    margin: 0 auto;
    padding: 3rem 20px;
  }
  
  .content a {
    color: #e91932;
    margin-right: 10px;
  }
  

/* ========================= */
/*        Home Page          */
/* ========================= */
  .home-above-the-fold {
    width: 100%;
    height: 100vh;
    background-color:#242424;
  }

  .overlay{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.66);
    text-align: center;
    color: #fff;
  }

  .overlay .general {
    max-width: 80vw;
    margin: 0 auto;
    height: 100vh;
    padding: 40vh 0 50vh 0;
  }

  .overlay.residential {
    max-width: 80vw;
    margin: 0 auto;
    height: 100vh;
    padding: 40vh 0 50vh 0;
  }

  .overlay .backdrop {
    backdrop-filter: blur(10px);
    margin-top: -47px; 
    padding: 30px 0;
  }

  .overlay h5 {
    color: #f2f2f2;
    width: 80vw;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 0 1em 1em 1em;
    font-weight: 400;
  }

  .responsive-headline {
    min-height: 60%;
  }

  .logo {
    display: block;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    min-height: 60%;
    padding-top: 6em;
  }

  .logo-nav {
    position: absolute;
    top: 0;
    left: 20px;
    height: 100%;
    display: block;
    width: 100%;
    max-width: 150px;
    margin: 0 auto;
    min-height: 100%;
  }

  .service-item {
    width: 80vw;
    padding-top: 20px;
  }

  .service-item .col {
    padding: 1em 0 0 0;
  }

.accordion {
  list-style: none;
  padding-left: 0;
}

.accordion_item {
  border-top: 1px solid #9f9f9f;
}

.button {
  font-size: 14px;
  background-color: #9F1223;
  color: #fff;
  text-align: center;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
}

.control {
  font-size: 15px;
}

.answer {
  background-color: #f7f7f7;
  padding: 20px;
  color: #000;
}

.accordion_item.active .button {
  background-color: #9F1223;
}
.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}

/* ========================= */
/*     Contact Us Form       */
/* ========================= */
.contactForm label {
  font-weight: 600;
}

.contactForm input, .contactForm textarea {
  margin: 5px 0 25px;
  width: 100%;
  line-height: 30px;
  padding-left: 7px;
  color: #a90000;
  font-weight: 600;
}

.contactForm input::placeholder, .contactForm textarea::placeholder {
  color: #000;
  opacity: 0.3;
}

.contactForm .submit {
  padding: 10px 20px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  background: #272727;
  border: none;
  cursor: pointer;
  height: auto;
  display: inline-block;
  border-radius: 0px;
}

.contactForm .submit:hover {
  color: #0D0D0D;
  background: #bfbfbf;
}

/* ========================= */
/*          Footer           */
/* ========================= */

footer {
    min-height: 150px;
    height: 100%;
    align-content: center;
    text-align: center;
    color: #525252;
}

footer .cmeq-logo {
  float: left;
  margin-right: -200px;
}

footer .cmeq-logo.mobile {
  margin-right: -350px;
}

.partner-wrapper {
  margin-bottom: -30px;
}

footer .partner img 
{
  width: 100%;
}

footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}

footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 45px;
  color: #525252 !important;
}

footer .social-links li:first-child {
  padding-left: 0;
}

footer li a:nth-child() {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #fff !important;
}

footer .copyright {
  width: 90vw;
  padding: 0 0 40px 0;
}

footer .copyright p,
footer .copyright p a {
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #525252 !important;
}

footer .copyright p{
  padding-left: 20px;
}

footer .social-links .fa {
  color: #525252;
}

@media only screen and (max-width: 480px) {
  .mobile {
    display: block;
  }

  .logo {
    padding-top: 20vh;
  }

  .service-list {
    padding-bottom: 25px;
  }

  .overlay .general {
    padding-top: 35vh !important;
    max-width: 100vw;
    width: 100vw;
  }

  .overlay .generator {
    padding-top: 28vh !important;
  }

  .overlay .backdrop {
    padding: 30px 20px;
  }

  .overlay h5 {
    font-size: medium;
  }

  .google-maps {
    border: 0px;
    max-width: 90vw;
    margin-left: -15px;
  }

  .partner-wrapper {
    margin-bottom: 0;
  }

  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
  .mobile {
    display: block;
  }

  .logo {
    padding-top: 80%;
  }

  .overlay > h5 {
    width: 100%;
  }

  .overlay h5 {
    font-size: medium;
  }

  .google-maps {
    border: 0px;
    max-width: 90vw;
    margin-left: -15px;
  }

  .partner-wrapper {
    margin-bottom: 0;
  }

  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .mobile {
    display: none;
  }

  .nav-area.under-the-fold {
    background-color:#242424;
  }

  .overlay h5 {
    font-size: medium;
  }

  .desktop {
    display: block;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .mobile {
    display: none;
  }

  .nav-area.under-the-fold {
    background-color:#242424;
  }

  .desktop {
    display: block;
  }
}

@media only screen and (min-width: 1201px) {
  .mobile {
    display: none;
  }

  .nav-area.under-the-fold {
    background-color:#242424;
  }

  .desktop {
    display: block;
  }
}